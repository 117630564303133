import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';
export const Merkenich: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Merkenich,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Merkenich"
          d="M579.2,291.5c1.4,13.2-4.7,28.6-11.2,40.5l-36-10l-24,6l4-16c-11.4-2.8-69.3-46.7-80-54l-16-36l-30-16v-4h6 l12-14l-54-32l27.5-51c0,0,96.7,74.1,143.7,111.7c8.9,6.1,18.3,14.4,27.2,22.2c8.7,7.7,16.5,16.5,23,26.2c0.1,0.1,0.1,0.2,0.2,0.2 c2.7,4.1,4.7,8.6,5.8,13.3C578.3,282.9,579,287.3,579.2,291.5z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 464.1253 252.5074)"
        >
          Merkenich
        </text>
      </g>
    </>
  );
});
